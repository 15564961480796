import React, { useCallback, useMemo } from "react";

import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams } from "@mobsuccess-devops/react-router-dom-search-params";

import clsx from "clsx";

import { PercentFormat, useLanguage } from "./Localized";

const useStyles = makeStyles((theme) => ({
  root: {
    //overflowY: 'hidden', // CA commented to leave borders flow on cards
    position: "relative",
  },
  row: {
    cursor: "pointer",
  },
  header: {
    position: "absolute",
    right: 0,
    left: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

function XTableHead({
  header,
  columns,
  numSelected,
  order,
  orderBy,
  onSelectAllClick,
  onRequestSort,
  rowCount,
  enableCheckboxes,
}) {
  const classes = useStyles();
  return (
    <TableHead>
      {header && (
        <TableRow>
          <TableCell colSpan={columns.length + (enableCheckboxes ? 1 : 0)}>
            {/* we do not want the cell to scroll with the table, use a little CSS hack to allow this*/}
            <div className={classes.header}>{header}</div>
            <div style={{ visibility: "hidden" }}>{header}</div>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {enableCheckboxes && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": t`Select all` }}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align || (column.numeric ? "right" : "left")}
            padding={column.disablePadding ? "none" : "default"}
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.enableSort !== false ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={(e) => onRequestSort(e, column.id)}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function XTableBody({ bodyRows }) {
  return <TableBody>{bodyRows}</TableBody>;
}

function getRowValue(row, column) {
  const value = row[column.id];
  if (column.numeric && typeof value === "number") {
    if (column.percentage) {
      return <PercentFormat value={value} />;
    } else {
      return i18n.number(Math.round(value * 10) / 10);
    }
  } else if (column.formatter) {
    return column.formatter(value, row);
  } else {
    return row[column.id];
  }
}

export default function XTable({
  header,
  tableClasses,
  tableContainerClasses,
  size,
  columns,
  rows,
  defaultOrder,
  defaultOrderBy,
  defaultRowsPerPage,
  paramsPrefix = "",
  getRowClassName = null,
  paramsSelected,
  onClickRow,
  enableCheckboxes,
  totalRow,
  enablePagination,
  enableClickRows,
}) {
  const classes = useStyles();
  const params = useSearchParams();
  const language = useLanguage();

  const [order, setOrder] = params.param(
    `${paramsPrefix}order`,
    defaultOrder || "asc"
  ); //useState(defaultOrder || 'asc');
  const [orderBy, setOrderBy] = params.param(
    `${paramsPrefix}orderby`,
    columns.some((col) => col.id === defaultOrderBy)
      ? defaultOrderBy
      : columns[0].id
  ); //useState(defaultOrderBy);
  const [selected, setSelected] = params.param(
    paramsSelected || `${paramsPrefix}selected`,
    []
  ); //useState([]);
  const [page, setPage] = params.param(`${paramsPrefix}page`, 0);
  const [rowsPerPage, setRowsPerPage] = params.param(
    `${paramsPrefix}rpp`,
    defaultRowsPerPage || 5
  );

  const handleRequestSort = useCallback(
    (_, property) => {
      if (orderBy === property) {
        setOrder(order === "asc" ? "desc" : "asc");
      } else {
        setOrderBy(property);
        setOrder(
          columns.filter((x) => x.id === property)[0].defaultOrder ||
            defaultOrder ||
            "asc"
        );
      }
    },
    [columns, order, defaultOrder, orderBy, setOrder, setOrderBy]
  );

  const handleSelectAllClick = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelected(
          rows
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((n) => n.id)
        );
      } else {
        setSelected([]);
      }
    },
    [rows, setSelected, page, rowsPerPage]
  );

  const handleClick = useCallback(
    (_, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = [...selected, id];
      } else {
        newSelected = selected.filter((x) => x !== id);
      }
      setSelected(newSelected);
    },
    [selected, setSelected]
  );

  const sortedRows = useMemo(() => {
    function getComparator(order, orderBy) {
      const [BIG, SMALL] = order === "asc" ? [-1, 1] : [1, -1];
      const isSelected = (id) => selected.indexOf(id) !== -1;
      return (a, b) => {
        const isSelectedA = isSelected(a.id);
        const isSelectedB = isSelected(b.id);
        if (isSelectedA && !isSelectedB) {
          return -1;
        } else if (isSelectedB && !isSelectedA) {
          return 1;
        }
        const { [`${orderBy}_value`]: va1 } = a;
        const { [`${orderBy}_value`]: vb1 } = b;
        const { [orderBy]: va2 } = a;
        const { [orderBy]: vb2 } = b;
        const va = va1 || va2 || 0;
        const vb = vb1 || vb2 || 0;
        if (typeof va === "number" && isNaN(va)) {
          return isNaN(vb) ? 0 : 1;
        }
        if (typeof vb === "number" && isNaN(vb)) {
          return isNaN(va) ? 0 : -1;
        }
        return va < vb ? BIG : va > vb ? SMALL : 0;
      };
    }

    return [].concat(rows).sort(getComparator(order, orderBy));
  }, [rows, order, orderBy, selected]);

  const currentRows = useMemo(() => {
    return sortedRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }, [sortedRows, page, rowsPerPage]);
  const bodyRows = useMemo(() => {
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return currentRows.map((row, i) => {
      const isItemSelected = isSelected(row.id);
      return (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id || i}
          selected={isItemSelected}
          className={clsx(classes.row, getRowClassName?.(row))}
          onClick={
            onClickRow
              ? (e) => onClickRow(e, row)
              : enableClickRows !== false && enableCheckboxes
              ? (e) => handleClick(e, row.id)
              : null
          }
        >
          {enableCheckboxes && (
            <TableCell padding="checkbox">
              <Checkbox
                checked={isItemSelected}
                onClick={
                  enableCheckboxes && enableClickRows === false
                    ? (e) => handleClick(e, row.id)
                    : null
                }
              />
            </TableCell>
          )}
          {columns.map((column) => (
            <TableCell
              align={column.numeric ? "right" : "left"}
              key={column.id}
            >
              {getRowValue(row, column, language)}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }, [
    getRowClassName,
    onClickRow,
    currentRows,
    classes.row,
    columns,
    enableCheckboxes,
    handleClick,
    selected,
    enableClickRows,
    language,
  ]);

  const tableFooter = useMemo(() => {
    return !totalRow ? null : (
      <TableFooter>
        <TableRow tabIndex={-1}>
          {columns.map((column) => (
            <TableCell
              align={column.numeric ? "right" : "left"}
              key={column.id}
            >
              {getRowValue(totalRow, column, language)}
            </TableCell>
          ))}
        </TableRow>
      </TableFooter>
    );
  }, [language, columns, totalRow]);

  const handleChangePage = useCallback(
    (_, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (e) => {
      setRowsPerPage(Number(e.target.value)) && setPage(0);
      setPage(0);
    },
    [setRowsPerPage, setPage]
  );

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <TableContainer classes={tableContainerClasses}>
          <Table
            className={classes.table}
            classes={tableClasses}
            size={size || "medium"}
          >
            <XTableHead
              columns={columns}
              header={header}
              //numSelected={selected.length}
              numSelected={
                currentRows
                  .map((x) => x.id)
                  .filter((x) => selected.indexOf(x) >= 0).length
              }
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={enablePagination === false ? rows.length : rowsPerPage}
              enableCheckboxes={enableCheckboxes}
            />
            <XTableBody bodyRows={bodyRows} />
            {tableFooter}
          </Table>
        </TableContainer>
        {enablePagination !== false && rows.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
