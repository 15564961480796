import React, { memo } from "react";

import { makeStyles } from "@material-ui/core";

import PropTypes from "prop-types";

const useStyles = makeStyles({
  explanation: {
    marginTop: "1rem",
    marginLeft: "1rem",
    paddingLeft: "1rem",
    borderLeft: "3px solid #ccc",
    fontStyle: "italic",
  },
});

function CancelReason({ cancelReason, cancelExplanation, tense = "present" }) {
  const classes = useStyles();
  if (cancelExplanation) {
    return (
      <>
        Le magasin a annulé la commande et a donné l'explication suivante :
        <div className={classes.explanation}>{cancelExplanation}</div>
      </>
    );
  }
  switch (cancelReason) {
    case "cancelled-before-order-ready":
      return tense === "present" ? (
        <>
          Le client s'est trompé ou s'est rétracté avant la préparation de sa
          commande (remboursement des 5€)
        </>
      ) : (
        <>
          Le client s'est trompé ou s'est rétracté avant la préparation de sa
          commande (remboursement des 5€)
        </>
      );
    case "products-missing":
      return tense === "present" ? (
        <>
          Des produits sont manquants et le client ne souhaite pas poursuivre sa
          commande (remboursement des 5€)
        </>
      ) : (
        <>
          Des produits étaient manquants et le client ne souhaitait pas
          poursuivre sa commande (remboursement des 5€)
        </>
      );
    case "cancelled-after-order-ready":
      return tense === "present" ? (
        <>
          Le client souhaite annuler alors que sa commande a déjà été préparée
          (PAS DE REMBOURSEMENT des 5€)
        </>
      ) : (
        <>
          Le client a souhaité annuler alors que sa commande avait déjà été
          préparée (PAS DE REMBOURSEMENT des 5€)
        </>
      );
    case "no-show":
      return tense === "present" ? (
        <>
          Le client ne s'est pas présenté dans le délai de 72h, j'annule sa
          commande (PAS DE REMBOURSEMENT des 5€)
        </>
      ) : (
        <>
          Le client ne s'est pas présenté dans le délai de 72h, sa commande a
          été annulée (PAS DE REMBOURSEMENT des 5€)
        </>
      );
    default:
      return null;
  }
}

CancelReason.propTypes = {
  cancelReason: PropTypes.string,
  cancelExplanation: PropTypes.string,
  tense: PropTypes.string,
};
CancelReason.defaultProps = {
  cancelReason: "",
  cancelExplanation: null,
  tense: "present",
};

export default memo(CancelReason);
