import React, { memo, useEffect } from "react";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ParamLink } from "@mobsuccess-devops/react-router-dom-search-params";

import { useDispatch, useSelector } from "react-redux";

import {
  fetchStoresForAccountId,
  selectAllStores,
} from "../../features/entities/storesSlice";
import { useDriveBackofficeRootUrl } from "../Backoffice/Backoffice";

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100vh",
    "& .leaflet-container": {
      width: "100%",
      height: "100%",
    },
  },
  table: {
    border: "1px solid #ccc",
    borderCollapse: "collapse",
    "& td, & th": {
      padding: 5,
      border: "1px solid #ccc",
    },
  },
});

function Homepage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const rootUrl = useDriveBackofficeRootUrl();

  useEffect(() => {
    dispatch(fetchStoresForAccountId({ accountId: 87 }));
  }, [dispatch]);

  const stores = useSelector(selectAllStores)
    .filter((store) => store.id_account === 87)
    .sort(({ store_name: storeNameA }, { store_name: storeNameB }) =>
      storeNameA.localeCompare(storeNameB)
    );

  return !stores ? null : (
    <Card>
      <Typography variant="h2">Liste des magasins Click&Collect</Typography>
      <table className={classes.table}>
        {stores.map((store) => (
          <tr>
            <td>
              <ParamLink to={`${rootUrl}/store/${store.store_id}`}>
                {store.store_name}
              </ParamLink>
            </td>
          </tr>
        ))}
      </table>
    </Card>
  );
}

export default memo(Homepage);
