import { sentryReduxEnhancer } from "@mobsuccess-devops/react-sentry";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import msApiSlice from "../features/api/msApiSlice";

/* entities */
import entitiesSlice from "../features/entities/entitiesSlice";

const store = configureStore({
  reducer: {
    msApi: msApiSlice,
    /* entities */
    entities: entitiesSlice,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
});
export default window.reduxStore = store;
