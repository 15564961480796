import React, { useMemo, useCallback, useState, useEffect, memo } from "react";

import { Trans } from "@lingui/macro";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import Barcode from "react-barcode";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ReactComponent as DiscountSvg } from "../../assets/icons/discount.svg";
import { get, axiosGet } from "../../features/api/msApi";
import { selectCanManageDriveOrders } from "../../features/entities/configSlice";
import {
  selectDriverOrderById,
  fetchOrderById,
  updateDriveOrderStatus,
  saveAdjustedCart,
} from "../../features/entities/driveOrderSlice";
import { formatStoreId } from "../../features/utils/formatStoreId";
import CancelReason from "../CancelReason";
import { getOrderDisplayId } from "../OrderId/OrderId";
import OrderStatus from "../OrderStatus";

const showStockMagasin = true;
const canCancelAllOrders = false;
const defaultPrepaidAmount = 5;

const useStyles = makeStyles({
  root: {
    margin: 15,
  },
  phone: {
    fontStyle: "italic",
  },
  productLabel: {
    fontWeight: "bold",
  },
  email: {
    fontStyle: "italic",
  },
  cartProductImage: {
    width: 100,
  },
  cartTable: {
    border: "1px solid #ccc",
    borderCollapse: "collapse",
    "& td, & th": {
      padding: 5,
      border: "1px solid #ccc",
    },
  },
  tdNumeric: {
    textAlign: "right",
  },
  cancelRadioGroup: {
    "& .MuiFormControlLabel-root": {
      marginBottom: 20,
    },
  },
  cancelReasonMissing: {
    color: "red",
  },
  print: {
    position: "absolute",
    top: 0,
    right: 0,
    marginTop: 20,
    marginRight: 20,
  },
  offerFaceValue: {
    fontWeight: "bold",
  },
  productOffer: {
    display: "flex",
    maxWidth: "calc(100% - 20px)",
    alignItems: "center",
    padding: "4px 8px",
    backgroundColor: "#ffdd00",
    borderRadius: 6,
    color: "#db161e",
    fontSize: 11,
    fontWeight: 600,
    textAlign: "left",
    "& svg": {
      width: 17,
      height: 17,
      marginRight: 8,
    },
  },
  barcodeContainer: {
    maxWidth: 520,
    "& .MuiTypography-h4": {
      display: "flex",
      placeContent: "space-between",
    },
  },
  barcode: {
    zoom: 0.75,
  },
  displayOrderIdWrapper: {
    display: "flex",
    placeItems: "center",
  },
  cartQuantity: {
    textAlign: "center",
  },

  productOutOfStock: {
    color: "red",
  },
  productPartiallyOutOfStock: {
    color: "#ff8c00",
  },

  changesPendingContainer: {
    position: "fixed",
    zIndex: 2,
    bottom: 0,
    left: 0,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: 20,
    background: "#c40101",
    boxShadow: "0px 0 3px 0px red",
    color: "white",
    fontSize: 18,
    placeItems: "center",
  },
  cancelPendingChanges: {
    margin: "0 20px",
    background: "transparent",
    fontSize: 14,
    textDecoration: "underline",
    "&:hover": {
      background: "transparent",
    },
    "& .MuiButton-label": {
      color: "white",
    },
  },
  submitPendingChanges: {
    "&:hover": {
      background: "#089b08",
      color: "white",
    },
  },
  dialogText: {
    whiteSpace: "break-spaces",
  },
  explanationLabel: {
    marginTop: 16,
    color: "red",
  },
  explanationTextField: {
    width: "100%",
  },
});

function AdjustedQuantity({ sku, cart, adjustedCart, onChange, readOnly }) {
  const options = [];
  const initial = cart[sku];
  const canManageDriveOrders = useSelector(selectCanManageDriveOrders);
  const adjusted = adjustedCart.hasOwnProperty(sku)
    ? adjustedCart[sku]
    : cart[sku];
  for (let i = 0; i <= initial; i++) {
    options.push(i);
  }
  return (
    <>
      {!canManageDriveOrders ? (
        <>{initial}</>
      ) : readOnly ? (
        <>{adjusted}</>
      ) : (
        <>
          <select value={adjusted} onChange={onChange}>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {adjusted !== initial && <> / {initial}</>}
        </>
      )}
    </>
  );
}

function CancelTransitionButton({
  orderId,
  defaultReason = "",
  hasRefund = false,
}) {
  const [reason, setReason] = useState(defaultReason);
  const [cancelExplanation, setCancelExplanation] = useState("");
  const classes = useStyles();

  const handleSetExplanation = useCallback(
    (e) => setCancelExplanation(e.target.value),
    [setCancelExplanation]
  );
  const handleSetReason = useCallback(
    (e) => setReason(e.target.value),
    [setReason]
  );

  const text = (
    <>
      {canCancelAllOrders && (
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={reason}
          onChange={handleSetReason}
          classes={{ root: classes.cancelRadioGroup }}
        >
          <FormControlLabel
            value="cancelled-before-order-ready"
            control={<Radio />}
            label={<CancelReason cancelReason="cancelled-before-order-ready" />}
          />
          <FormControlLabel
            value="products-missing"
            control={<Radio />}
            label={<CancelReason cancelReason="products-missing" />}
          />
          <FormControlLabel
            value="cancelled-after-order-ready"
            control={<Radio />}
            label={<CancelReason cancelReason="cancelled-after-order-ready" />}
          />
          <FormControlLabel
            value="no-show"
            control={<Radio />}
            label={<CancelReason cancelReason="no-show" />}
          />
        </RadioGroup>
      )}
      {!canCancelAllOrders && (
        <>
          <div className={classes.explanationLabel}>
            En cas d'annulation totale, cette commande donnera lieu à un
            contrôle.
          </div>
          <div className={classes.explanationLabel}>
            Veuillez fournir une raison pour l'annulation de cette commande :
          </div>
          <div className={classes.explanationTextFieldContainer}>
            <TextField
              className={classes.explanationTextField}
              variant="outlined"
              rows={4}
              multiline
              value={cancelExplanation}
              onChange={handleSetExplanation}
            />
          </div>
        </>
      )}
    </>
  );

  return (
    <TransitionStateButton
      orderId={orderId}
      newStatus="order-cancelled-by-store"
      explanation={cancelExplanation}
      text={text}
      buttonEnabled={
        !canCancelAllOrders ? !!cancelExplanation.length : !!reason
      }
      buttonText={
        hasRefund
          ? "Annuler la commande en REMBOURSANT le client"
          : "Annuler la commande en ne REMBOURSANT pas le client"
      }
      extraParams={{ cancelReason: reason, cancelExplanation }}
      buttonStyle={{ backgroundColor: "#ffafaf" }}
    >
      {hasRefund
        ? "ANNULER la commande en la remboursant"
        : "ANNULER la commande sans remboursement"}
    </TransitionStateButton>
  );
}

function TransitionStateButton({
  orderId,
  newStatus,
  children,
  text,
  buttonText,
  buttonStyle,
  buttonEnabled = true,
  extraParams = {},
}) {
  const [open, setOpen] = useState(false);
  const canManageDriveOrders = useSelector(selectCanManageDriveOrders);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setOpen(false);
    (async () => {
      await dispatch(
        updateDriveOrderStatus({ orderId, newStatus, extraParams })
      );
      await dispatch(fetchOrderById({ orderId }));
    })();
  };

  const classes = useStyles();

  return !canManageDriveOrders ? null : (
    <div className={classes.root}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={buttonStyle}
      >
        {children}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Modifier le statut de la commande</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogText}>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
          <Button
            onClick={handleChange}
            color="primary"
            style={buttonStyle}
            disabled={!buttonEnabled}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

async function getStorePrice({ storeId }) {
  return (
    await axiosGet({
      entity: "driveJsonData",
      params: { item: "base", store_id: formatStoreId(storeId) },
    })
  ).data;
}

async function getOffers() {
  return (
    await axiosGet({
      entity: "driveJsonData",
      params: { item: "gift-card-offers" },
    })
  ).data;
}

async function getBackofficeProductList() {
  return (
    await axiosGet({
      entity: "driveJsonData",
      params: { item: "backoffice-product-list" },
    })
  ).data;
}

function ProductBarcode({ product }) {
  const ean = product ? product.ean : null;
  const classes = useStyles();
  //return product ? <Barcode value={product.ean} /> : null;
  return ean ? (
    <div className={classes.barcode}>
      <Barcode format="EAN13" value={ean} />
    </div>
  ) : null;

  /*  {
  width: 2,
  height: 100,
  format: "CODE128",
  displayValue: true,
  fontOptions: "",
  font: "monospace",
  textAlign: "center",
  textPosition: "bottom",
  textMargin: 2,
  fontSize: 20,
  background: "#ffffff",
  lineColor: "#000000",
  margin: 10,
  marginTop: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginRight: undefined
}*/
}

async function getStock({ dispatch, storeId }) {
  try {
    return (
      (
        await get({
          dispatch,
          entity: "driveOrder",
          verb: "listStock",
          params: { storeId },
        })
      ).data || {}
    );
  } catch (e) {
    return {};
  }
}

const cancelTimeInDays = 7;

function OrderComponent({ orderId, order }) {
  const dispatch = useDispatch();
  const [products, setProducts] = useState({});
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    (async () => {
      setProducts(await getBackofficeProductList());
      setOffers(await getOffers());
    })();
  }, []);

  const classes = useStyles();
  let {
    storeId,
    storeName,
    date,
    firstname,
    lastname,
    address1,
    postcode,
    city,
    country,
    cancelReason,
    cancelExplanation,
    email,
    phone,
    cart,
    "Status_order-cancelled-by-store_At": orderCancelledByStore,
    "Status_order-cancelled-by-customer_At": orderCancelledByCustomer,
    adjustedCart: adjustedCartBase = {},
    cartPriceCheckout,
    cartContents,
    status,
    prepaymentAmount,
    "Status_order-ready_At": statusOrderReadyAt,
    externalCustomerId,
    consentVIP,
  } = order;

  const prepaidAmount = prepaymentAmount ?? defaultPrepaidAmount;
  const isVip = !!externalCustomerId && !!Number(consentVIP);
  const [stock, setStock] = useState({});
  useMemo(async () => {
    if (showStockMagasin) {
      setStock(await getStock({ dispatch, storeId }));
    }
  }, [dispatch, storeId]);

  const initialAdjustedCart = useMemo(
    () => ({ ...cart, ...adjustedCartBase }),
    [cart, adjustedCartBase]
  );
  const [adjustedCart, setAdjustedCart] = useState(initialAdjustedCart);

  /* to debug an order on a different store
  cart = { 397805: 1, 444910: 1, 555230: 1 };
  cartPrice = 19.61;
  storeId = 1538;
  */

  const [storePrice, setStorePrice] = useState(null);
  useEffect(() => {
    (async () => {
      setStorePrice(await getStorePrice({ storeId }));
    })();
  }, [storeId, setStorePrice]);

  //const cartTotalInitial = Object.values(cart).reduce((s, x) => s + x, 0);
  const cartTotal = Object.values(
    Object.fromEntries(
      Object.entries(adjustedCart).filter(([sku]) => sku.match(/^[0-9]+$/))
    )
  ).reduce((s, x) => s + x, 0);
  const cartHasBeenModifiedSinceOrder = useMemo(
    () => JSON.stringify(adjustedCart) !== JSON.stringify(cart),
    [adjustedCart, cart]
  );
  const cartHasBeenModified = useMemo(
    () => JSON.stringify(initialAdjustedCart) !== JSON.stringify(adjustedCart),
    [initialAdjustedCart, adjustedCart]
  );
  const getProductPrice = useCallback(
    (sku) =>
      Number(
        (
          (isVip
            ? cartContents[sku]?.priceVIP || cartContents[sku]?.price
            : cartContents[sku]?.price) ||
          storePrice?.[sku] ||
          products[sku]?.price ||
          ""
        ).replace(/EUR/, "")
      ),
    [cartContents, storePrice, products, isVip]
  );
  const cartPrice = !cartHasBeenModifiedSinceOrder
    ? cartPriceCheckout
    : Object.entries(adjustedCart)
        .map(([sku, quantity]) => getProductPrice(sku) * quantity)
        .reduce((s, x) => s + x, 0);

  const handlePrint = useCallback(() => window.print(), []);

  const skus = useMemo(() => {
    return Object.keys(cart)
      .filter((sku) => cart[sku])
      .sort((skuA, skuB) => {
        if (!products) {
          return 0;
        }
        const productA = products[skuA];
        const productB = products[skuB];
        if (!productA || !productB) {
          return 0;
        }
        const { module: moduleA = "", gamme: gammeA = "" } = productA;
        const { module: moduleB = "", gamme: gammeB = "" } = productB;
        if (!gammeA) {
          return 1;
        }
        if (!gammeB) {
          return -1;
        }
        return gammeA.localeCompare(gammeB) || moduleA.localeCompare(moduleB);
      });
  }, [products, cart]);

  const [orderDisplayId, setOrderDisplayId] = useState(null);
  useEffect(() => {
    (async () =>
      setOrderDisplayId(
        await getOrderDisplayId({ orderId, createdAt: date })
      ))();
  }, [orderId, date, setOrderDisplayId]);

  const [savePendingChangesInProgress, setSavePendingChangesInProgress] =
    useState(false);
  const handleCancelPendingChanges = useCallback(() => {
    setAdjustedCart({ ...initialAdjustedCart });
  }, [setAdjustedCart, initialAdjustedCart]);
  const handleSubmitPendingChanges = useCallback(async () => {
    setSavePendingChangesInProgress(true);
    await dispatch(
      saveAdjustedCart({ orderId, adjustedCart, adjustedCartPrice: cartPrice })
    );
    await dispatch(fetchOrderById({ orderId }));
    setSavePendingChangesInProgress(false);
  }, [
    adjustedCart,
    orderId,
    setSavePendingChangesInProgress,
    cartPrice,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(fetchOrderById({ orderId }));
  }, [dispatch, orderId]);

  const orderCanBeCancelled =
    new Date(
      new Date(statusOrderReadyAt).getTime() + cancelTimeInDays * 86400e3
    ) < new Date();

  return !storePrice ? null : (
    <>
      <div className={classes.print}>
        <Button onClick={handlePrint}>
          <Trans>Print</Trans>
        </Button>
      </div>
      <Card>
        <Typography variant="h3">
          Commande passée le {new Date(date).toLocaleDateString("fr-FR")} à{" "}
          {new Date(date).toLocaleTimeString("fr-FR")}
        </Typography>
        <div className={classes.barcodeContainer}>
          <Typography variant="h4" className={classes.displayOrderIdWrapper}>
            Numéro de commande : {orderDisplayId}
            {!!orderDisplayId && (
              <span className={classes.barcode}>
                <Barcode format="EAN13" value={orderDisplayId} />
              </span>
            )}
          </Typography>
          <Typography variant="h4" className={classes.displayOrderIdWrapper}>
            Commande Click&Collect:
            {!!orderDisplayId && (
              <span className={classes.barcode}>
                <Barcode format="EAN13" value="3491959026406" />
              </span>
            )}
          </Typography>
        </div>
        <p>
          {status === "order-cancelled-by-store"
            ? `Cette commande à été annulée par le magasin le : ${new Date(
                orderCancelledByStore
              ).toLocaleDateString("fr-FR")}`
            : null}
          {status === "order-cancelled-by-customer"
            ? `Cette commande à été annulée par le client le : ${new Date(
                orderCancelledByCustomer
              ).toLocaleDateString("fr-FR")}`
            : null}
        </p>
        <Typography variant="h4">
          Statut :
          <OrderStatus status={status} cancelReason={cancelReason} />
        </Typography>
        {!!cancelReason && (
          <Typography variant="4">
            <CancelReason
              tense="past"
              cancelExplanation={cancelExplanation}
              cancelReason={cancelReason}
            />
          </Typography>
        )}
      </Card>
      <Card>
        <Typography variant="h3">Magasin</Typography>
        <div>
          {storeName} ({formatStoreId(storeId)})
        </div>
      </Card>
      <Card>
        <Typography variant="h3">Coordonnées client</Typography>

        <div>
          <b>
            {firstname} {lastname}
          </b>
        </div>
        <div>{address1}</div>
        <div>
          {postcode} {city} ({country})
        </div>

        <div>
          <span className={classes.phone}>Téléphone</span> : {phone}
        </div>
        <div>
          <span className={classes.email}>Email</span> : {email}
        </div>
      </Card>

      <Card>
        <Typography variant="h3">Commande</Typography>

        {cartHasBeenModified && !status.match(/^order-cancelled-/) && (
          <div className={classes.changesPendingContainer}>
            {savePendingChangesInProgress ? (
              <>Enregistrement en cours…</>
            ) : !cartTotal ? (
              <>
                Cette commande ne contient plus d'articles.
                <CancelTransitionButton
                  orderId={orderId}
                  defaultReason="products-missing"
                  hasRefund
                />
              </>
            ) : (
              <>
                Les quantités des articles de cette commande ont été modifiées.
                <Button
                  onClick={handleCancelPendingChanges}
                  className={classes.cancelPendingChanges}
                >
                  Annuler
                </Button>
                <Button
                  onClick={handleSubmitPendingChanges}
                  className={classes.submitPendingChanges}
                >
                  ENREGISTRER
                </Button>
              </>
            )}
          </div>
        )}

        <table className={classes.cartTable}>
          <tr>
            <th>Code produit</th>
            <th>Photo</th>
            <th>Article</th>
            {showStockMagasin && <th>Stock magasin</th>}
            {showStockMagasin && <th>Stock C&C</th>}
            <th>Gamme</th>
            <th>Module</th>
            <th>EAN</th>
            <th>Quantité</th>
            <th>PU</th>
            <th>Prix total</th>
          </tr>
          {skus.map((sku) => {
            const cartContent = (cartContents || {})[sku] || {};
            const product = products[sku] || {};
            const productPrice = getProductPrice(sku);
            const imageSrc = product.imageSrc;
            const imageJpg = imageSrc
              ?.replace(
                /gifi.fr\/media\/catalog\/product\/\d+\/\d+\//,
                "gifi.fr/images/product/"
              )
              .replace(/livraison.gifi.fr/, "www.gifi.fr");
            return (
              <tr
                key={sku}
                className={clsx(
                  adjustedCart[sku] === 0 && classes.productOutOfStock,
                  adjustedCart[sku] &&
                    adjustedCart[sku] !== cart[sku] &&
                    classes.productPartiallyOutOfStock
                )}
              >
                <td>{cartContent.offerId ? null : sku}</td>
                <td>
                  <img
                    alt={product.sku}
                    className={classes.cartProductImage}
                    src={imageJpg}
                  />
                </td>
                <td>
                  {cartContent.offerId ? (
                    <>
                      <div>
                        {
                          offers.find((x) => x.Id === cartContent.offerId)
                            ?.BackofficeTitle
                        }
                      </div>
                      <div>
                        Valeur faciale :{" "}
                        <span className={classes.offerFaceValue}>
                          {cartContent.faceValue.toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={classes.productLabel}>
                        {product.label}
                      </div>
                      {(cartContent.offers || []).map((offer) => (
                        <div key={offer.id} className={classes.productOffer}>
                          <DiscountSvg />
                          {
                            offers.find((x) => x.Id === offer.id)
                              ?.BackofficeTitle
                          }
                        </div>
                      ))}
                    </>
                  )}
                </td>
                {showStockMagasin && (
                  <td>
                    {(status === "preparing-order" ? cart[sku] : 0) +
                      (stock[sku] || 0)}
                  </td>
                )}
                {showStockMagasin && (
                  <td>
                    {Math.max(
                      0,
                      (status === "preparing-order" ? cart[sku] : 0) +
                        (productPrice < 1.49
                          ? (stock[sku] || 0) - 4
                          : productPrice > 1.49 && productPrice <= 4.99
                          ? (stock[sku] || 0) - 3
                          : (stock[sku] || 0) - 2)
                    )}
                  </td>
                )}
                <td>{product.gamme}</td>
                <td>{product.module}</td>
                <td>
                  <ProductBarcode product={product} />
                </td>
                <td className={clsx(classes.cartQuantity, classes.tdNumeric)}>
                  {cartContent.offerId ? null : (
                    <AdjustedQuantity
                      readOnly={status.match(/^order-cancelled-/)}
                      sku={sku}
                      cart={cart}
                      adjustedCart={adjustedCart}
                      onChange={(e) =>
                        setAdjustedCart({
                          ...adjustedCart,
                          [sku]: Number(e.target.value),
                        })
                      }
                    />
                  )}
                </td>
                <td className={classes.tdNumeric}>
                  {!product.price
                    ? ""
                    : productPrice.toLocaleString(undefined, {
                        style: "currency",
                        currency: "EUR",
                      })}
                </td>
                <td className={classes.tdNumeric}>
                  {cartContent.offerId
                    ? null
                    : !productPrice
                    ? "N/A"
                    : (adjustedCart[sku] * productPrice).toLocaleString(
                        undefined,
                        {
                          style: "currency",
                          currency: "EUR",
                        }
                      )}
                </td>
              </tr>
            );
          })}
        </table>
      </Card>

      <Card>
        <Typography variant="h3">Informations de paiement</Typography>
        <table className={classes.cartTable}>
          <tr>
            <td>Nombre d'articles : </td>
            <td className={classes.tdNumeric}>{cartTotal}</td>
          </tr>
          <tr>
            <td>Montant total : </td>
            <td className={classes.tdNumeric}>
              <Typography variant="h2">
                {cartPrice.toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </td>
          </tr>
          <tr>
            <td>Montant prépayé : </td>
            <td className={classes.tdNumeric}>
              {prepaidAmount.toLocaleString(undefined, {
                style: "currency",
                currency: "EUR",
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
          <tr>
            <td>Montant à régler : </td>
            <td className={classes.tdNumeric}>
              {Math.max(0, cartPrice - prepaidAmount).toLocaleString(
                undefined,
                {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 2,
                }
              )}
            </td>
          </tr>
          {cartPrice < prepaidAmount && (
            <tr>
              <td>
                Le montant prépayé est supérieur au montant de la commande.
                <br />
                Le solde sera remboursé au client dès retrait de celle ci :
              </td>
              <td className={classes.tdNumeric}>
                {(prepaidAmount - cartPrice).toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 2,
                })}{" "}
              </td>
            </tr>
          )}
        </table>
      </Card>

      <Card>
        <div>
          <Typography variant="h3" className={classes.status}>
            Statut :
            <OrderStatus status={status} cancelReason={cancelReason} />
          </Typography>
          {!!cancelReason && (
            <CancelReason
              tense="past"
              cancelReason={cancelReason}
              cancelExplanation={cancelExplanation}
            />
          )}
          {status === "preparing-order" && cartPrice >= prepaidAmount && (
            <TransitionStateButton
              orderId={orderId}
              newStatus="order-ready"
              text="Cette action va marquer la commande comme étant prête. Un email sera envoyé au client."
              buttonText="Marquer la commande prête"
              buttonStyle={{ backgroundColor: "#a4ea79" }}
            >
              Marquer la commande PRÊTE
            </TransitionStateButton>
          )}
          {status === "order-ready" && (
            <TransitionStateButton
              orderId={orderId}
              newStatus="complete"
              text={`Cette action va marquer la commande comme étant terminée : le client a récupéré sa commande. Un email sera envoyé au client pour le remercier.${
                cartPrice < prepaidAmount
                  ? `\n\nLe montant final de la commande est inférieur au montant pré-payé, la somme de ${(
                      prepaidAmount - cartPrice
                    ).toLocaleString(undefined, {
                      style: "currency",
                      currency: "EUR",
                      maximumFractionDigits: 2,
                    })} sera re-créditée sur la carte du client.`
                  : ""
              }`}
              buttonText="Confirmer la bonne réception par le client"
              buttonStyle={{ backgroundColor: "#a4ea79" }}
            >
              CONFIRMER le Click&Collect
            </TransitionStateButton>
          )}

          {(canCancelAllOrders ||
            ((status === "preparing-order" || status === "order-ready") &&
              cartPrice < prepaidAmount)) && (
            <CancelTransitionButton
              defaultReason="products-missing"
              orderId={orderId}
              hasRefund
            />
          )}

          {orderCanBeCancelled && status === "order-ready" && (
            <CancelTransitionButton defaultReason="no-show" orderId={orderId} />
          )}
        </div>
      </Card>
    </>
  );
}

function Order() {
  const { orderId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderById({ orderId }));
  }, [dispatch, orderId]);

  const order = useSelector((state) => selectDriverOrderById(state, orderId));
  if (!order) {
    return null;
  }
  return <OrderComponent orderId={orderId} order={order} />;
}

export default memo(Order);
