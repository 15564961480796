import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import store from "./app/store";
import Localized from "./components/Localized/LocalizedBase";
import { theme } from "./features/utils/theme";
import "./index.css";

const basename = typeof window._msBaseUrl === "string" ? window._msBaseUrl : "";

render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter basename={basename}>
          <Localized>
            <App />
          </Localized>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  window.top.document.getElementById("react_root--DriveBackoffice")
);
