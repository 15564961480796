import React, { useContext, useState, useCallback, useMemo } from "react";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

import { fr as pluralsFr, en as pluralsEn } from "make-plural/plurals";

import en from "../../locales/en/messages";
import fr from "../../locales/fr/messages";

const language = "fr";

window.i18n = i18n;
i18n.loadLocaleData("en", { plurals: pluralsEn });
i18n.loadLocaleData("fr", { plurals: pluralsFr });
i18n.load("en", en.messages);
i18n.load("fr", fr.messages);

export function activate(locale) {
  if (activate.current !== locale) {
    activate.current = locale;
    i18n.activate(locale);
  }
}

export const LanguageContext = React.createContext({
  language,
  onChangeLanguage: () => null,
});

export function getAllowedLanguages() {
  return ["fr", "en"];
}

export default function Localized({ children }) {
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const handleChangeLanguage = useCallback((newLanguage) => {
    setCurrentLanguage(newLanguage);
  }, []);

  const value = useMemo(() => {
    return {
      language: currentLanguage,
      onChangeLanguage: handleChangeLanguage,
    };
  }, [currentLanguage, handleChangeLanguage]);

  activate(currentLanguage);
  return (
    <LanguageContext.Provider value={value}>
      <I18nProvider i18n={i18n} language={currentLanguage}>
        {children}
      </I18nProvider>
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  return useContext(LanguageContext).language;
}

export function useOnChangeLanguage() {
  return useContext(LanguageContext).onChangeLanguage;
}

export function useI18n() {
  const language = useLanguage();
  activate(language);
  return i18n;
}
