import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/msApi";

const storesAdapter = createEntityAdapter({});

export const fetchStoresForAccountId = createAsyncThunk(
  "entities/fetchStoresForAccountId",
  async ({ force, accountId } = {}, { getState, dispatch }) => {
    const state = getState();
    const totalStores = selectTotalStores(state);
    if (!force && (totalStores || state.entities.stores.pending > 1)) {
      return;
    }
    return (
      await get({
        dispatch,
        entity: "stores",
        params: { id_account: accountId },
      })
    ).data;
  }
);

export const slice = createSlice({
  name: "stores",
  initialState: storesAdapter.getInitialState({ pending: 0 }),
  reducers: {
    loaded: (state, data) => data.payload,
  },
  extraReducers: {
    [fetchStoresForAccountId.pending]: (state, action) => {
      state.pending++;
    },
    [fetchStoresForAccountId.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        storesAdapter.setAll(state, data);
      }
    },
    [fetchStoresForAccountId.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const { reducer } = slice;
export default reducer;

export const { selectTotal: selectTotalStores, selectAll: selectAllStores } =
  storesAdapter.getSelectors((state) => state.entities.stores);
