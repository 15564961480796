/**
 * This is mostly a wrapper of ReactDateRange, that uses
 * Localized.js to help with localization of the date range
 * interface.
 */
import React, { memo, useMemo } from "react";

import { t } from "@lingui/macro";

// theme css file
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from "date-fns";
import PropTypes from "prop-types";
import { DateRangePicker, createStaticRanges } from "react-date-range";
// react date range
import "react-date-range/dist/styles.css";
// main css file
import "react-date-range/dist/theme/default.css";

import { useDateFnsLocale } from "../Localized";

const weekStartsOn = 1;

function MSDateRangePicker({ children, ...props }) {
  const locale = useDateFnsLocale();

  const defineds = useMemo(
    () => ({
      startOfWeek: startOfWeek(new Date(), { locale, weekStartsOn }),
      endOfWeek: endOfWeek(new Date(), { locale, weekStartsOn }),
      startOfLastWeek: startOfWeek(addDays(new Date(), -7), {
        locale,
        weekStartsOn,
      }),
      endOfLastWeek: endOfWeek(addDays(new Date(), -7), {
        locale,
        weekStartsOn,
      }),
      startOfToday: startOfDay(new Date(), { locale, weekStartsOn }),
      endOfToday: endOfDay(new Date(), { locale, weekStartsOn }),
      startOfYesterday: startOfDay(addDays(new Date(), -1), {
        locale,
        weekStartsOn,
      }),
      endOfYesterday: endOfDay(addDays(new Date(), -1), {
        locale,
        weekStartsOn,
      }),
      startOfMonth: startOfMonth(new Date(), { locale, weekStartsOn }),
      endOfMonth: endOfMonth(new Date(), { locale, weekStartsOn }),
      startOfLastMonth: startOfMonth(addMonths(new Date(), -1), {
        locale,
        weekStartsOn,
      }),
      endOfLastMonth: endOfMonth(addMonths(new Date(), -1), {
        locale,
        weekStartsOn,
      }),
    }),
    [locale]
  );

  const inputRanges = useMemo(
    () => [
      {
        label: t`days up to today`,
        range(value) {
          return {
            startDate: addDays(
              defineds.startOfToday,
              (Math.max(Number(value), 1) - 1) * -1
            ),
            endDate: defineds.endOfToday,
          };
        },
        getCurrentValue(range) {
          if (!isSameDay(range.endDate, defineds.endOfToday)) {
            return "-";
          }
          if (!range.startDate) {
            return "∞";
          }
          return (
            differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
          );
        },
      },
      {
        label: t`days starting today`,
        range(value) {
          const today = new Date();
          return {
            startDate: today,
            endDate: addDays(today, Math.max(Number(value), 1) - 1),
          };
        },
        getCurrentValue(range) {
          if (!isSameDay(range.startDate, defineds.startOfToday)) {
            return "-";
          }
          if (!range.endDate) {
            return "∞";
          }
          return (
            differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1
          );
        },
      },
    ],
    [defineds]
  );

  const staticRanges = useMemo(
    () =>
      createStaticRanges([
        {
          label: t`Today`,
          range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
          }),
        },
        {
          label: t`Yesterday`,
          range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
          }),
        },

        {
          label: t`This Week`,
          range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
          }),
        },
        {
          label: t`Last Week`,
          range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek,
          }),
        },
        {
          label: t`This Month`,
          range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
          }),
        },
        {
          label: t`Last Month`,
          range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
          }),
        },
        {
          label: t`Lifetime`,
          range: () => ({
            startDate: new Date("2015-01-01"),
            endDate: defineds.endOfToday,
          }),
        },
      ]),
    [defineds]
  );

  return (
    <DateRangePicker
      inputRanges={inputRanges}
      staticRanges={staticRanges}
      weekStartsOn={weekStartsOn}
      {...props}
    >
      {children}
    </DateRangePicker>
  );
}

MSDateRangePicker.propTypes = {
  children: PropTypes.node,
};

MSDateRangePicker.defaultProps = {
  children: null,
};

export default memo(MSDateRangePicker);
