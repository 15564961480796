import { memo, useEffect, useState } from "react";

import { Buffer } from "buffer";
import sha256 from "js-sha256";
import PropTypes from "prop-types";

export async function getOrderDisplayId({ orderId, createdAt }) {
  if (!orderId || !createdAt) {
    return null;
  }

  const b = Buffer.from(sha256(orderId.replace(/-/g, "")), "hex");
  let displayId =
    (
      (b[0] ^ b[1] ^ (b[2] + b[3]) ^ b[4]) +
      ((b[5] ^ b[6] ^ b[7] ^ b[8]) << 8) +
      ((b[9] ^ b[10] ^ b[11]) << 16)
    ).toString() + (b[12] ^ b[13] ^ b[14] ^ b[15]).toString();
  if (displayId.length > 10) {
    displayId = displayId.substr(displayId.length - 10, 10);
  }

  const m = createdAt.match(/(...)Z/);
  displayId += m[1];

  if (displayId.length > 12) {
    displayId = displayId.substr(0, 12);
  }

  return displayId;
}

function OrderId({ orderId, createdAt }) {
  const [displayId, setDisplayId] = useState(null);

  useEffect(() => {
    (async () => {
      setDisplayId(await getOrderDisplayId({ orderId, createdAt }));
    })();
  }, [orderId, createdAt, setDisplayId]);

  return displayId ? displayId : null;
}

OrderId.propTypes = {
  orderId: PropTypes.string,
  createdAt: PropTypes.string,
};

OrderId.defaultProps = {
  orderId: "",
  createdAt: "",
};

export default memo(OrderId);
