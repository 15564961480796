import React, { useMemo, useState, useCallback, memo } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Popover from "@material-ui/core/Popover";
import { useTheme } from "@material-ui/core/styles";

import PropTypes from "prop-types";

import { useDurationFormatter, useDateFnsLocale } from "../Localized";
import MSDateRangePicker from "../MSDateRangePicker";

function MSDateRange({ date1, date2, onDatePickerRangeChange }) {
  const [dateRangePickerAnchor, setDateRangePickerAnchor] = useState(null);
  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const theme = useTheme();
  const dateFnLocale = useDateFnsLocale();
  const durationFormatter = useDurationFormatter();

  const datePickerRanges = useMemo(
    () => ({
      startDate: new Date(date1),
      endDate: new Date(date2),
      key: "selection",
    }),
    [date1, date2]
  );

  const handleClickDate = useCallback((e) => {
    setDateRangePickerAnchor(e.currentTarget);
    setDateRangePickerOpen((open) => setDateRangePickerOpen(!open));
  }, []);

  const handleCloseDateRangerPicker = useCallback(
    () => setDateRangePickerOpen(false),
    [setDateRangePickerOpen]
  );

  return (
    <>
      <ButtonGroup variant="contained">
        <Button className="ms-date-picker" onClick={handleClickDate}>
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            class="kt-svg-icon"
            style={useMemo(() => ({ width: 16, height: 16 }), [])}
          >
            <g>
              <path
                class="st1"
                d="M18,3.9H6c-1.1,0-2,0.9-2,2v12.4c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V5.9C20,4.8,19.1,3.9,18,3.9z M8,18.2
		c0,0.3-0.2,0.5-0.5,0.5H6.2c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V18.2z M8,14.8
		c0,0.3-0.2,0.5-0.5,0.5H6.2c-0.3,0-0.5-0.2-0.5-0.5v-1.3C5.7,13.2,6,13,6.2,13h1.3C7.8,13,8,13.2,8,13.5V14.8z M8,11.4
		c0,0.3-0.2,0.5-0.5,0.5H6.2c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3C7.8,9.6,8,9.8,8,10.1V11.4z M11.4,18.2
		c0,0.3-0.2,0.5-0.5,0.5H9.6c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V18.2z M11.4,14.8
		c0,0.3-0.2,0.5-0.5,0.5H9.6c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V14.8z M11.4,11.4
		c0,0.3-0.2,0.5-0.5,0.5H9.6c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V11.4z M14.8,18.2
		c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V18.2z M14.8,14.8
		c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V14.8z M14.8,11.4
		c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V11.4z M18.3,18.2
		c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V18.2z M18.3,14.8
		c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V14.8z M18.3,11.4
		c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-1.3c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V11.4z"
                opacity="0.4"
              />
              <path
                class="st1"
                d="M4,5.9v1.9h16V5.9c0-1.1-0.9-2-2-2H6C4.9,3.9,4,4.8,4,5.9z"
                opacity="0.6"
              />
              <path
                class="st2"
                d="M6.9,2.4L6.9,2.4c-0.6,0-1.1,0.5-1.1,1.1v1.8c0,0.6,0.5,1.1,1.1,1.1h0C7.5,6.5,8,6,8,5.3V3.5
		C8,2.9,7.5,2.4,6.9,2.4z"
                opacity="1"
              />
              <path
                class="st2"
                d="M17.1,2.4L17.1,2.4c-0.6,0-1.1,0.5-1.1,1.1v1.8c0,0.6,0.5,1.1,1.1,1.1h0c0.6,0,1.1-0.5,1.1-1.1V3.5
		C18.3,2.9,17.8,2.4,17.1,2.4z"
                opacity="1"
              />
            </g>
          </svg>
          {durationFormatter(date1, date2)}
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={dateRangePickerAnchor}
        open={dateRangePickerOpen}
        onClose={handleCloseDateRangerPicker}
        anchorOrigin={useMemo(
          () => ({
            vertical: "top",
            horizontal: "right",
          }),
          []
        )}
        transformOrigin={useMemo(
          () => ({
            vertical: "top",
            horizontal: "right",
          }),
          []
        )}
      >
        <MSDateRangePicker
          locale={dateFnLocale}
          onChange={onDatePickerRangeChange}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={useMemo(() => [datePickerRanges], [datePickerRanges])}
          direction="horizontal"
          rangeColors={useMemo(
            () => [theme.palette.secondary.main, "#000000", "#000000"],
            [theme.palette.secondary.main]
          )}
        />
      </Popover>
    </>
  );
}

MSDateRange.propTypes = {
  date1: PropTypes.string,
  date2: PropTypes.string,
  onDatePickerRangeChange: PropTypes.func,
};

MSDateRange.defaultProps = {
  date1: "",
  date2: "",
  onDatePickerRangeChange: () => null,
};

export default memo(MSDateRange);
