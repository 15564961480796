import { createTheme } from "@material-ui/core/styles";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

export const widgetAdding = 25;
const fontFamily = "Poppins, Helvetica, sans-serif";

export const dark = "#d1d1d1";
export const primary = "#632b92";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#f1f1f1",
      light: "#fefefe",
      dark: dark,
      contrastText: "#212529",
    },
    secondary: {
      main: primary,
      contrastText: "white",
    },
    text: {
      primary: "#595d6e",
    },
  },
  typography: {
    htmlFontSize: 13,
    fontFamily: fontFamily,
    h2: {
      fontWeight: 500,
      fontSize: "2rem",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.3rem",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.2rem",
    },
    h5: {
      fontWeight: 400,
      fontSize: "1.1rem",
    },
    h6: {
      fontWeight: 400,
      fontSize: "1.0rem",
    },
    button: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: "normal",
      textTransform: "normal",
    },
  },
  shape: {
    borderRadius: "0.25rem",
  },
  props: {
    MuiButtonGroup: {
      disableElevation: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    TableSortLabel: {
      //IconComponent:
    },
    MuiCheckbox: {
      icon: <CircleUnchecked />,
      checkedIcon: <CircleChecked />,
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "1rem",
      },
      h3: {
        lineHeight: 1.2,
        marginBottom: "0.2rem",
      },
    },

    MuiIconButton: {
      root: {
        color: primary,
      },
    },

    MuiInputBase: {
      root: {
        color: primary,
        fontSize: "1rem",
        lineHeight: 1.75,
        borderRadius: "0.25rem",
      },
      input: {
        height: "inherit",
      },
    },

    MuiInput: {
      input: {
        marginLeft: 6,
      },

      underline: {
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:before": {
          borderBottomColor: primary,
        },
        "&:hover:not(.Mui-disabled)": {
          //backgroundColor: "#f1f1f1",
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: `1px solid ${primary}`,
        },
      },
    },

    MuiFilledInput: {
      root: {
        backgroundColor: "#f1f1f1",
      },
      input: {
        padding: "6px 6px",
      },
      underline: {
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:before": {
          borderBottomColor: primary,
        },
        "&:hover:not(.Mui-disabled)": {
          backgroundColor: "#f1f1f1",
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: `1px solid ${primary}`,
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: primary,
        },
      },
      input: {
        padding: "6px 24px",
      },
    },

    MuiSelect: {
      icon: {
        color: primary,
      },
      outlined: {
        border: `1px solid ${primary}`,
        "&:hover:not(.Mui-disabled)": {
          backgroundColor: "#f1f1f1",
        },
      },
      select: {
        paddingLeft: 6,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1rem",
      },
    },

    MuiStepIcon: {
      root: {
        color: dark,
        "&.MuiStepIcon-active, &.MuiStepIcon-completed": {
          color: primary,
        },
      },
      text: {
        fill: "white",
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: primary,
        opacity: 0.2,
      },
    },

    /* MuiCircularProgress */
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: primary,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: primary,
      },
    },

    MuiRadio: {
      root: {
        color: primary,
      },
    },

    MuiAppBar: {
      colorPrimary: {
        color: "white",
        backgroundColor: primary,
        "& .MuiTabs-indicator": {
          backgroundColor: "white",
        },
      },
    },

    MuiTab: {
      fullWidth: {
        backgroundColor: "rgba(255,255,255,0.5)",
        "&.Mui-selected": {
          backgroundColor: "inherit",
        },
      },
    },

    MuiSlider: {
      root: {
        color: primary,
      },
      valueLabel: {
        "& span span": {
          color: "white",
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: primary,
      },
      colorPrimary: {
        "&.Mui-checked": {
          color: primary,
        },
      },
    },

    MuiSwitch: {
      switchBase: {
        color: primary,
      },
      track: {
        backgroundColor: dark,
      },
    },

    MuiFormControlLabel: {
      label: {
        color: primary,
      },
    },

    /* Paper/Cards */
    MuiPaper: {
      root: {
        color: "#595d6e",
        "& button,  & select, & input": {
          fontFamily: fontFamily,
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow: "0px 0px 13px 0px rgba(82, 63, 105, 0.05)",
        padding: widgetAdding,
        borderBottom: "solid 1px rgb(235, 237, 242)",
        background: "white",
        borderRadius: 4,
        "& .MuiTable-root": {
          width: `calc(100% + ${widgetAdding * 2}px)`,
          position: "relative",
          left: -widgetAdding,
          "& .MuiTableCell-root": {
            paddingLeft: widgetAdding,
          },
        },
      },
    },

    /* Button */
    MuiButton: {
      root: {
        backgroundColor: "#f1f1f1",
        "&:hover": {
          backgroundColor: dark,
        },
        "&.Mui-disabled": {
          "& .MuiButton-label": {
            color: "#ccc",
          },
          backgroundColor: "#f1f1f1",
          cursor: "not-allowed",
        },
      },
      label: {
        color: primary,
      },
      contained: {
        verticalAlign: "baseline",
        backgroundColor: "#f1f1f1",
      },
      containedSecondary: {
        "& .MuiButton-label": {
          color: "white",
        },
      },
    },

    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: dark,
        },
      },
    },

    /* ButtonGroup */
    MuiButtonGroup: {
      root: {
        verticalAlign: "middle",
      },
    },

    MuiAutocomplete: {
      root: {
        verticalAlign: "middle",
      },
      listbox: {
        fontSize: "1rem",
      },
      inputRoot: {
        padding: "0 !important",
        border: `1px solid ${primary}`,
        borderRadius: 3,
      },
      input: {
        border: "none !important",
      },
    },

    /* Tables */
    MuiTableContainer: {
      root: {
        marginTop: "1rem",
        overflowX: "initial",
      },
    },
    MuiTableSortLabel: {
      active: {
        color: `${primary} !important`,
      },
      root: {
        "&:hover": {
          color: `${primary} !important`,
        },
      },
      iconDirectionAsc: {
        color: `${primary} !important`,
      },
      iconDirectionDesc: {
        color: `${primary} !important`,
      },
    },
    MuiTableHead: {
      root: {
        borderTop: "solid 1px rgb(240, 243, 255)",
        borderBottom: "solid 1px rgb(240, 243, 255)",
        backgroundColor: "#f1f1f1",
      },
    },
    MuiTableRow: {
      hover: {
        "&:hover": {
          backgroundColor: "#54d5c0 !important",
        },
      },
    },
    MuiTableCell: {
      head: {
        color: primary,
      },
      root: {
        borderTop: "solid 1px rgb(240, 243, 255)",
        borderBottom: "solid 1px rgb(240, 243, 255)",
        padding: "16px 10px",
        fontSize: "1rem",
      },
      sizeSmall: {
        padding: "16px 10px",
        fontSize: "1rem",
      },
    },

    MuiDrawer: {
      paper: {
        backgroundColor: "#171717",
        color: "rgba(255,255,255,0.8)",
        "& .MuiButtonBase-root .MuiSvgIcon-root": {
          stroke: "rgba(255,255,255,0.6)",
        },
        "& .MuiButtonBase-root.Mui-selected .MuiSvgIcon-root": {
          fill: "rgba(255,255,255,0.4)",
          stroke: "rgba(255,255,255,0.3)",
        },
        "& .MuiListItem-button:hover": {
          backgroundColor: "#777",
        },
        "& .MuiTypography-h3": {
          marginLeft: 15,
          color: "#bbb",
          textTransform: "uppercase",
        },
      },
    },
  },
});
