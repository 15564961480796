import React, { useEffect, useState, useMemo, useCallback, memo } from "react";

import { Plural } from "@lingui/macro";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";

import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";

import { useDriveBackofficeRootUrl } from "../../components/Backoffice/Backoffice";
import MSDateRange from "../../components/MSDateRange";
import { useGetStatusLabel } from "../../components/OrderStatus/OrderStatus";
import Orders from "../../components/Orders";
import Search from "../../components/Search";
import {
  fetchAllOrdersForStore,
  fetchOrderByDisplayId,
  selectAllDriveOrders,
} from "../entities/driveOrderSlice";
import {
  fetchStoresForAccountId,
  selectAllStores,
} from "../entities/storesSlice";
import { validEmailRegExp } from "../utils/email";
import { formatPhoneNumber } from "../utils/phoneNumber";
import { sluggize } from "../utils/sluggize";

const useStyles = makeStyles({
  headerBar: {
    display: "inline-block",
  },
  searchContainer: {
    marginLeft: 20,
    float: "right",
    paddingLeft: 15,
    display: "inline-block",
  },
  searchFailed: {
    color: "red",
    zIndex: 2,
    position: "relative",
    top: -7,
  },
  dateRangePicker: {
    marginLeft: 10,
    display: "inline-block",
  },
  ordersStats: {
    marginLeft: 10,
    display: "inline-block",
  },
  ordersSummary: {
    marginTop: 10,
  },
});

function dateToString(d) {
  const year = (d.getYear() + 1900).toString();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
}

function transformSearch({ search, searchBy, phoneCountry }) {
  if (searchBy === "Phone") {
    return formatPhoneNumber({ phone: search, country: phoneCountry });
  } else if (searchBy.match(/Slug$/)) {
    return sluggize(search);
  }
  return search;
}

export default function DriveBackofficeStore() {
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const showAllStores = storeId === "all";
  const [currentSearch, setCurrentSearch] = useState("");
  const [orderIdCurrentSearch, setOrderIdCurrentSearch] = useState("");
  const [, setSearchBuster] = useState(0);
  const [date1, setDate1] = useState(
    dateToString(new Date(Date.now() - 31 * 86400e3))
  );
  const [date2, setDate2] = useState(
    dateToString(new Date(Date.now() - 0 * 86400e3))
  );

  const rootUrl = useDriveBackofficeRootUrl();

  useMemo(() => {
    dispatch(
      fetchAllOrdersForStore({
        storeId,
        fromDate: date1,
        toDate: date2,
      })
    );
  }, [dispatch, date1, date2, storeId]);
  const allOrders = useSelector(selectAllDriveOrders);

  const [showPreparingOrder, setShowPreparingOrder] = useState(true);
  const [showPreparingOrderLate, setShowPreparingOrderLate] = useState(false);
  const [showOrderReady, setShowOrderReady] = useState(false);
  const [showOrderReadyLate, setShowOrderReadyLate] = useState(false);
  const [showPendingPayment, setShowPendingPayment] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [showComplete, setShowComplete] = useState(false);

  const handleToggleShowPreparingOrder = useCallback(() => {
    setShowPreparingOrder(true);
    setShowPreparingOrderLate(false);
    setShowOrderReady(false);
    setShowOrderReadyLate(false);
    setShowPendingPayment(false);
    setShowCancelled(false);
    setShowComplete(false);
  }, [
    setShowPreparingOrder,
    setShowPreparingOrderLate,
    setShowOrderReady,
    setShowOrderReadyLate,
    setShowPendingPayment,
    setShowCancelled,
    setShowComplete,
  ]);

  const handleToggleShowPreparingOrderLate = useCallback(() => {
    setShowPreparingOrder(false);
    setShowPreparingOrderLate(true);
    setShowOrderReady(false);
    setShowOrderReadyLate(false);
    setShowPendingPayment(false);
    setShowCancelled(false);
    setShowComplete(false);
  }, [
    setShowPreparingOrder,
    setShowPreparingOrderLate,
    setShowOrderReady,
    setShowOrderReadyLate,
    setShowPendingPayment,
    setShowCancelled,
    setShowComplete,
  ]);

  const handleToggleShowOrderReady = useCallback(() => {
    setShowPreparingOrder(false);
    setShowPreparingOrderLate(false);
    setShowOrderReady(true);
    setShowOrderReadyLate(false);
    setShowPendingPayment(false);
    setShowCancelled(false);
    setShowComplete(false);
  }, [
    setShowPreparingOrder,
    setShowPreparingOrderLate,
    setShowOrderReady,
    setShowOrderReadyLate,
    setShowPendingPayment,
    setShowCancelled,
    setShowComplete,
  ]);

  const handleToggleShowOrderReadyLate = useCallback(() => {
    setShowPreparingOrder(false);
    setShowPreparingOrderLate(false);
    setShowOrderReady(false);
    setShowOrderReadyLate(true);
    setShowPendingPayment(false);
    setShowCancelled(false);
    setShowComplete(false);
  }, [
    setShowPreparingOrder,
    setShowPreparingOrderLate,
    setShowOrderReady,
    setShowOrderReadyLate,
    setShowPendingPayment,
    setShowCancelled,
    setShowComplete,
  ]);

  const handleToggleShowPendingPayment = useCallback(() => {
    setShowPreparingOrder(false);
    setShowPreparingOrderLate(false);
    setShowOrderReady(false);
    setShowOrderReadyLate(false);
    setShowPendingPayment(true);
    setShowCancelled(false);
    setShowComplete(false);
  }, [
    setShowPreparingOrder,
    setShowPreparingOrderLate,
    setShowOrderReady,
    setShowOrderReadyLate,
    setShowPendingPayment,
    setShowCancelled,
    setShowComplete,
  ]);

  const handleToggleShowCancelled = useCallback(() => {
    setShowPreparingOrder(false);
    setShowPreparingOrderLate(false);
    setShowOrderReady(false);
    setShowOrderReadyLate(false);
    setShowCancelled(true);
    setShowPendingPayment(false);
    setShowComplete(false);
  }, [
    setShowPreparingOrder,
    setShowPreparingOrderLate,
    setShowOrderReady,
    setShowOrderReadyLate,
    setShowPendingPayment,
    setShowCancelled,
    setShowComplete,
  ]);

  const handleToggleShowComplete = useCallback(() => {
    setShowPreparingOrder(false);
    setShowPreparingOrderLate(false);
    setShowOrderReady(false);
    setShowOrderReadyLate(false);
    setShowComplete(true);
    setShowPendingPayment(false);
    setShowCancelled(false);
  }, [
    setShowPreparingOrder,
    setShowPreparingOrderLate,
    setShowOrderReady,
    setShowOrderReadyLate,
    setShowPendingPayment,
    setShowCancelled,
    setShowComplete,
  ]);

  const orders = useMemo(() => {
    return allOrders
      .filter((x) => allOrders || x.storeId === storeId)
      .filter(
        ({ date }) =>
          new Date(date) >= new Date(`${date1}T00:00:00.000`) &&
          new Date(date) <= new Date(`${date2}T23:59:59.999`)
      )
      .filter(({ status }) =>
        status === "pending-payment" ? showPendingPayment : true
      )
      .filter(({ status }) =>
        status === "payment-failed" ? showPendingPayment : true
      )
      .filter(({ status }) =>
        status === "preparing-order"
          ? showPreparingOrder || showPreparingOrderLate
          : true
      )
      .filter(({ date }) =>
        showPreparingOrderLate
          ? new Date(date) <= new Date(Date.now() - 3 * 86400e3)
          : true
      )
      .filter(({ "Status_order-ready_At": statusOrderReadyAt }) =>
        showOrderReadyLate
          ? new Date(statusOrderReadyAt) <= new Date(Date.now() - 3 * 86400e3)
          : true
      )
      .filter(({ status }) =>
        status === "order-ready" ? showOrderReady || showOrderReadyLate : true
      )
      .filter(({ status }) =>
        status === "order-cancelled-by-store" ||
        status === "order-cancelled-by-customer"
          ? showCancelled
          : true
      )
      .filter(({ status }) => (status === "complete" ? showComplete : true))
      .map((row) => ({
        ...row,
        quantity: Object.values(row.cart).reduce((s, x) => s + x, 0),
      }));
  }, [
    storeId,
    allOrders,
    showPendingPayment,
    showOrderReady,
    showOrderReadyLate,
    showCancelled,
    showPreparingOrder,
    showPreparingOrderLate,
    showComplete,
    date1,
    date2,
    //orderIdCurrentSearch,
  ]);

  useEffect(() => {
    dispatch(fetchStoresForAccountId({ accountId: 87 }));
  }, [allOrders, dispatch]);
  const stores = useSelector(selectAllStores);
  const store = stores.find((store) => store.store_id.toString() === storeId);
  const storeName = store?.store_name;
  const [searchBy, setSearchBy] = useState("OrderDisplayId");
  const [phoneCountry, setPhoneCountry] = useState("FR");

  const isSearchValid =
    searchBy === "OrderDisplayId"
      ? !!currentSearch.match(/^[0-9]{1,12}$/)
      : searchBy === "LastnameSlug"
      ? !!sluggize(currentSearch).match(/^\w+$/)
      : searchBy === "EmailSlug"
      ? !!currentSearch.match(validEmailRegExp)
      : searchBy === "Phone"
      ? !!currentSearch.match(/^[0-9]+$/)
      : false;
  const handleSetSearch = useCallback(
    (e) => setCurrentSearch(e.target.value),
    [setCurrentSearch]
  );
  const handleChangePhoneCountry = useCallback(
    (e) => setPhoneCountry(e.target.value),
    [setPhoneCountry]
  );
  const [hasSearchFailed, setHasSearchFailed] = useState(false);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const handleCloseSearchDialog = useCallback(
    () => setOpenSearchDialog(false),
    [setOpenSearchDialog]
  );
  const handleSearch = useCallback(() => {
    (async () => {
      if (["LastnameSlug", "EmailSlug", "Phone"].indexOf(searchBy) >= 0) {
        setOpenSearchDialog(true);
        return;
      }
      setOrderIdCurrentSearch(currentSearch);
      setHasSearchFailed(false);
      setSearchBuster(Math.random());
      if (currentSearch) {
        const searchedOrder = await dispatch(
          fetchOrderByDisplayId({ orderDisplayId: currentSearch })
        ).payload;
        if (!searchedOrder) {
          setHasSearchFailed(true);
        }
      }
    })();
  }, [
    currentSearch,
    setOrderIdCurrentSearch,
    dispatch,
    searchBy,
    setOpenSearchDialog,
  ]);

  const handleChangeSearchBy = useCallback(
    (e) => setSearchBy(e.target.value),
    [setSearchBy]
  );

  const searchedOrder = useMemo(() => {
    return allOrders.find((x) => x.orderDisplayId === orderIdCurrentSearch);
  }, [allOrders, orderIdCurrentSearch]);

  const handleChangeDatePickerRange = useCallback(
    ({ selection: { startDate, endDate } }) => {
      setDate1(dateToString(startDate));
      setDate2(dateToString(endDate));
    },
    [setDate1, setDate2]
  );

  return searchedOrder ? (
    <Redirect
      push
      to={`${rootUrl}/store/${searchedOrder.storeId}/order/${searchedOrder.id}`}
    />
  ) : (
    <>
      <Search
        storeId={storeId}
        open={openSearchDialog}
        searchBy={searchBy}
        search={transformSearch({
          search: currentSearch,
          searchBy,
          phoneCountry,
        })}
        handleClose={handleCloseSearchDialog}
      />
      <StoreOrders
        {...{
          storeName,
          showPreparingOrder,
          showPreparingOrderLate,
          showOrderReady,
          showOrderReadyLate,
          handleToggleShowOrderReady,
          handleToggleShowOrderReadyLate,
          handleToggleShowPreparingOrder,
          handleToggleShowPreparingOrderLate,
          handleToggleShowPendingPayment,
          showPendingPayment,
          showCancelled,
          handleToggleShowCancelled,
          showComplete,
          handleToggleShowComplete,
          currentSearch,
          handleSetSearch,
          isSearchValid,
          handleSearch,
          hasSearchFailed,
          orders,
          searchBy,
          handleChangeSearchBy,
          phoneCountry,
          handleChangePhoneCountry,
          showAllStores,
          date1,
          date2,
          handleChangeDatePickerRange,
        }}
      />
    </>
  );
}

function generateCSV({
  rows,
  columns,
  selectedColumns,
  separator = ",",
  enclosingCharacter = '"',
}) {
  const escape = (x) =>
    `${enclosingCharacter}${(x || "")
      .toString()
      .replace(/"/g, '""')}${enclosingCharacter}`;
  const availableColumns = columns.filter(
    (column) => selectedColumns.indexOf(column.property) >= 0
  );
  const header = availableColumns
    .map((availableColumn) => availableColumn.label)
    .map(escape)
    .join(separator);
  const body = rows
    .map((row) =>
      availableColumns
        .map((availableColumn) => row[availableColumn.property])
        .map(escape)
        .join(separator)
    )
    .join("\n");
  return `${header}\n${body}\n`;
}

const StoreOrders = memo(function StoreOrders({
  storeName,
  showPreparingOrder,
  showPreparingOrderLate,
  showOrderReady,
  showOrderReadyLate,
  handleToggleShowOrderReady,
  handleToggleShowOrderReadyLate,
  handleToggleShowPreparingOrder,
  handleToggleShowPreparingOrderLate,
  handleToggleShowPendingPayment,
  showPendingPayment,
  showCancelled,
  handleToggleShowCancelled,
  showComplete,
  handleToggleShowComplete,
  currentSearch,
  handleSetSearch,
  isSearchValid,
  handleSearch,
  hasSearchFailed,
  orders,
  searchBy,
  handleChangeSearchBy,
  phoneCountry,
  handleChangePhoneCountry,
  showAllStores,
  date1,
  date2,
  handleChangeDatePickerRange,
}) {
  const getStatusLabel = useGetStatusLabel();
  const handleDownload = useCallback(() => {
    const columns = [
      { property: "storeId", label: "ID magasin" },
      { property: "storeName", label: "Nom du magasin" },
      { property: "orderDisplayId", label: "Numéro de commande" },
      { property: "date", label: "Date" },
      { property: "client", label: "Client" },
      { property: "cartPrice", label: "Montant du panier" },
      { property: "status", label: "Statut" },
    ];
    const csv = generateCSV({
      rows: orders.map((row) => ({
        ...row,
        date: new Date(row.date).toISOString(),
        client: `${row.firstname} ${row.lastname}`,
        status: getStatusLabel(row.status, row.cancelReason),
      })),
      columns,
      selectedColumns: columns.map((x) => x.property),
    });
    const type = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      ? "application/csv"
      : "text/csv";
    const blob = new Blob(["\uFEFF", csv], { type });

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `Commandes C&C - ${new Date().toISOString()}.csv`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
  }, [orders, getStatusLabel]);
  const classes = useStyles();
  return (
    <>
      <Card>
        <Typography variant="h2">{storeName}</Typography>
      </Card>

      <Card>
        <div className={classes.headerBar}>
          <ButtonGroup variant="contained">
            <Button
              color={showPreparingOrder ? "secondary" : "primary"}
              onClick={handleToggleShowPreparingOrder}
            >
              À préparer
            </Button>
            <Button
              color={showPreparingOrderLate ? "secondary" : "primary"}
              onClick={handleToggleShowPreparingOrderLate}
            >
              À préparer ≥ 3j
            </Button>
            <Button
              color={showOrderReady ? "secondary" : "primary"}
              onClick={handleToggleShowOrderReady}
            >
              À donner
            </Button>
            <Button
              color={showOrderReadyLate ? "secondary" : "primary"}
              onClick={handleToggleShowOrderReadyLate}
            >
              À donner — Hors délai
            </Button>
            {false && (
              <Button
                color={showPendingPayment ? "secondary" : "primary"}
                onClick={handleToggleShowPendingPayment}
              >
                En attente de paiement
              </Button>
            )}
            <Button
              color={showCancelled ? "secondary" : "primary"}
              onClick={handleToggleShowCancelled}
            >
              Annulées
            </Button>
            <Button
              color={showComplete ? "secondary" : "primary"}
              onClick={handleToggleShowComplete}
            >
              Terminées
            </Button>
          </ButtonGroup>

          <div className={classes.dateRangePicker}>
            <MSDateRange
              date1={date1}
              date2={date2}
              onDatePickerRangeChange={handleChangeDatePickerRange}
            />
          </div>

          <span className={classes.searchContainer}>
            Rechercher par{" "}
            <select value={searchBy} onChange={handleChangeSearchBy}>
              <option value="OrderDisplayId">numéro de commande</option>
              <option value="LastnameSlug">nom de famille</option>
              <option value="EmailSlug">email</option>
              <option value="Phone">numéro de téléphone</option>
            </select>{" "}
            {searchBy === "Phone" && (
              <select value={phoneCountry} onChange={handleChangePhoneCountry}>
                <option value="FR">FR</option>
                <option value="BE">BE</option>
                <option value="LU">LU</option>
                <option value="CH">CH</option>
              </select>
            )}
            :
            <Input value={currentSearch} onChange={handleSetSearch} />
            <Button
              disabled={!isSearchValid || !currentSearch}
              onClick={handleSearch}
            >
              Rechercher
            </Button>
            {hasSearchFailed && (
              <div className={classes.searchFailed}>
                Aucune commande avec ce numéro
              </div>
            )}
          </span>
        </div>

        {orders.length > 0 && (
          <div className={classes.ordersSummary}>
            <Button onClick={handleDownload}>
              Télécharger la liste en CSV
            </Button>
            <div className={classes.ordersStats}>
              {window.location.href.match(/debugplural/) ? (
                <span className="backoffice-plural">
                  {" "}
                  <Plural
                    value={orders.length}
                    one="#src.features.BackofficeStore.orderFind"
                    other="#src.features.BackofficeStore.ordersFind"
                  />
                </span>
              ) : (
                <span className="backoffice-span">
                  {orders.length === 1
                    ? "1 commande trouvée"
                    : `${orders.length} commandes trouvées`}
                </span>
              )}{" "}
              (total ={" "}
              {orders
                .map(({ cartPrice }) => cartPrice)
                .reduce((s, x) => s + Number(x), 0)
                .toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              , panier moyen ={" "}
              {(
                orders
                  .map(({ cartPrice }) => cartPrice)
                  .reduce((s, x) => s + Number(x), 0) / orders.length
              ).toLocaleString("fr-FR", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              )
            </div>
          </div>
        )}

        <div>
          <Orders
            showCancelled={showCancelled}
            showMultipleStores={showAllStores}
            orders={orders}
          />
        </div>
      </Card>
    </>
  );
});
