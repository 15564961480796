import React, { useState, useMemo, useRef, memo } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { fetchOrdersBySearchFilter } from "../../features/entities/driveOrderSlice";
import Orders from "../Orders";

const styles = (theme) => ({
  dialog: {
    minWidth: "calc(100% - 100px)",
    minHeight: "calc(100% - 100px)",
  },
  root: {
    display: "flex",
    padding: theme.spacing(2),
    margin: 0,
    "& .MuiTypography-h6": {
      display: "inline-flex",
      flexGrow: 2,
    },
  },
});
const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Search({ storeId, open, searchBy, search, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchInProgress, setSearchInProgress] = useState(true);
  const [result, setResult] = useState([]);

  const searchParamsRef = useRef();
  useMemo(
    () => (searchParamsRef.current = { storeId, search, searchBy }),
    [storeId, search, searchBy]
  );

  useMemo(async () => {
    if (open) {
      if (searchParamsRef.current.inProgress) {
        return;
      }
      searchParamsRef.current.inProgress = true;
      setSearchInProgress(true);
      setResult([]);
      // start the search
      const data = await dispatch(
        fetchOrdersBySearchFilter(searchParamsRef.current)
      );
      setSearchInProgress(false);
      if (data.payload) {
        setResult(data.payload);
      }
      searchParamsRef.current.inProgress = false;
    }
  }, [open, dispatch, searchParamsRef, setResult]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={useMemo(
        () => ({
          paper: classes.dialog,
        }),
        [classes.dialog]
      )}
    >
      <DialogTitle onClose={onClose}>
        <Typography variant="h2">Recherche avancée</Typography>
      </DialogTitle>

      <DialogContent dividers>
        {searchInProgress && <Typography>Recherche en cours…</Typography>}
        {!searchInProgress &&
          (!result.length ? (
            <Typography>
              Aucune commande ne correspond aux critères de recherche.
            </Typography>
          ) : (
            <Orders showMultipleStores orders={result} />
          ))}
      </DialogContent>

      <DialogContent dividers>
        <DialogActions>
          <Button onClick={onClose}>Fermer</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

Search.propTypes = {
  storeId: PropTypes.string,
  open: PropTypes.bool,
  searchBy: PropTypes.string,
  search: PropTypes.string,
  onClose: PropTypes.func,
};

Search.defaultProps = {
  storeId: "",
  open: false,
  searchBy: "",
  search: "",
  onClose: () => null,
};

export default memo(Search);
