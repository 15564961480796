import { combineReducers } from "@reduxjs/toolkit";

import configSlice from "./configSlice";
import driveOrderSlice from "./driveOrderSlice";
import querySlice from "./querySlice";
import storesSlice from "./storesSlice";

export default combineReducers({
  query: querySlice,
  config: configSlice,
  driveOrder: driveOrderSlice,
  stores: storesSlice,
});
