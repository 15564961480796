export function getEntryPointUrlBase() {
  if (process.env.NODE_ENV !== "production") {
    return "";
  }

  const msBaseUrl = window._msBaseUrl;
  if (typeof msBaseUrl === "string") {
    return msBaseUrl;
  }

  if (window.location.pathname.match(/^\/components($|\/)/)) {
    return "/components";
  }

  return "";
}
