import React from "react";

import { initSentry } from "@mobsuccess-devops/react-sentry";

import Backoffice from "./components/Backoffice";
import Hotjar from "./components/Hotjar";

function getSentryDsnFromEnv() {
  const dsnByUrlMatch = JSON.parse(
    process.env.REACT_APP_SENTRY_DSN_BY_URL_MATCH ?? "[]"
  );
  const { dsn } =
    dsnByUrlMatch.find(({ match }) =>
      window.location.href.match(new RegExp(match))
    ) ?? {};
  return dsn;
}

const dsn =
  window._msSentryDsn ||
  getSentryDsnFromEnv() ||
  "https://b98ed17119d14424a7ee9fe6ad6e3bff@o1028474.ingest.sentry.io/6487638";

initSentry({
  dsn,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "local",
});

function App() {
  return (
    <div className="App">
      <Hotjar />
      <Backoffice />
    </div>
  );
}

export default App;
