/* eslint-disable @mobsuccess-devops/mobsuccess/variables */

/* eslint-disable @mobsuccess-devops/mobsuccess/react */
import React, { useMemo, useContext, createContext, memo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { ParamProvider } from "@mobsuccess-devops/react-router-dom-search-params";

import { useDispatch } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import BackofficeStore from "../../features/backoffice/BackofficeStore";
import "../../features/backoffice/backoffice.scss";
import { fetchConfig } from "../../features/entities/configSlice";
import { formatStoreId } from "../../features/utils/formatStoreId";
import Homepage from "../Homepage";
import Order from "../Order";

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100vh",
    "& .leaflet-container": {
      width: "100%",
      height: "100%",
    },
  },
  table: {
    border: "1px solid #ccc",
    borderCollapse: "collapse",
    "& td, & th": {
      padding: 5,
      border: "1px solid #ccc",
    },
  },
});

const DriveBackofficeContext = createContext();

export function useDriveBackofficeRootUrl() {
  return useContext(DriveBackofficeContext).rootUrl;
}

function Backoffice() {
  const match = useRouteMatch();
  const classes = useStyles();
  const dispatch = useDispatch();
  const matchUrl = match.url.replace(/\/$/, "");
  const driveBackofficeContextValue = useMemo(
    () => ({
      rootUrl: `${matchUrl}`,
      formatStoreId: formatStoreId,
    }),
    [matchUrl]
  );
  useMemo(() => {
    dispatch(fetchConfig());
  }, [dispatch]);
  return (
    <div className={classes.root}>
      <style type="text/css" media="">{`
        body, .MuiPaper-root: { 
        display:none;
        .kt-aside, .kt-subheader, #kt_header {
        display:none !important;
        }
        .kt-wrapper {
        padding-left: 0!important;
        padding-top: 0!important;
        }
        `}</style>
      <ParamProvider keep={["lang"]}>
        <DriveBackofficeContext.Provider value={driveBackofficeContextValue}>
          <Switch>
            <Route
              path={`${matchUrl}/store/:accountId?/:storeId/order/:orderId`}
              render={() => <Order />}
            />
            <Route
              path={`${matchUrl}/order/:orderId`}
              render={() => <Order />}
            />
            <Route
              path={`${matchUrl}/store/:accountId?/:storeId`}
              render={() => <BackofficeStore />}
            />
            <Route path={`${matchUrl}/`} render={() => <Homepage />} />
          </Switch>
        </DriveBackofficeContext.Provider>
      </ParamProvider>
    </div>
  );
}

export default memo(Backoffice);
