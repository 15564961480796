import React, { memo, useCallback } from "react";

import { makeStyles } from "@material-ui/core";

import clsx from "clsx";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router";

import OrderId from "../OrderId";
import OrderStatus from "../OrderStatus";
import { getEntryPointUrlBase } from "../PlatformUrlBase";
import XTable from "../XTable";

const useStyles = makeStyles({
  rowALongTimeAgo: {
    "& td": {
      color: "red",
    },
  },
});

function Orders({ showMultipleStores, showCancelled, orders }) {
  const classes = useStyles();

  const columns = [
    {
      id: "id",
      label: "Numéro de commande",
      formatter: (orderId, row) => (
        <OrderId orderId={orderId} createdAt={row.date} />
      ),
    },
    ...(showMultipleStores
      ? [
          {
            id: "storeName",
            label: "Nom du magasin",
          },
        ]
      : []),
    {
      id: "date",
      label: "Date de commande",
      formatter: (date) =>
        `le ${new Date(date).toLocaleDateString("fr-FR")} à ${new Date(
          date
        ).toLocaleTimeString("fr-FR")}`,
    },
    {
      id: "client",
      label: "Client",
      formatter: (_, row) => `${row.firstname} ${row.lastname}`,
    },
    {
      id: "quantity",
      label: "Nb articles",
    },
    {
      id: "cartPrice",
      label: "Prix",
      formatter: (value, row) =>
        ((value) =>
          value
            ? value.toLocaleString(undefined, {
                style: "currency",
                currency: "EUR",
                maximumFractionDigits: 2,
              })
            : value)(row.adjustedCartPrice || row.cartPrice),
    },
    ...(showCancelled
      ? [
          {
            showCancelled: showCancelled,
            id: "Status_order-cancelled-by-store_At",
            label: "Date d'annulation",
            formatter: (date, row) =>
              `le ${new Date(
                date || row["Status_order-cancelled-by-customer_At"]
              ).toLocaleDateString("fr-FR")} à ${new Date(
                date || row["Status_order-cancelled-by-customer_At"]
              ).toLocaleTimeString("fr-FR")}`,
          },
        ]
      : []),
    {
      id: "status",
      label: "Statut",
      formatter: (status, row) => (
        <OrderStatus showIcon status={status} cancelReason={row.cancelReason} />
      ),
    },
  ];

  const match = useRouteMatch();
  const matchUrl = match.url.replace(/\/$/, "");

  const handleClickRow = useCallback(
    (e, row) => {
      window.location = `${getEntryPointUrlBase()}${matchUrl}/order/${row.id}`;
    },
    [matchUrl]
  );

  const getRowClassName = useCallback(
    ({ status, "Status_order-ready_At": statusOrderReadyAt, date }) => {
      const aLongTimeAgo = new Date(Date.now() - 3 * 86400e3);
      return clsx(
        status === "order-ready" &&
          statusOrderReadyAt &&
          new Date(statusOrderReadyAt) < aLongTimeAgo &&
          classes.rowALongTimeAgo,
        status === "preparing-order" &&
          date &&
          new Date(date) < aLongTimeAgo &&
          classes.rowALongTimeAgo
      );
    },
    [classes]
  );

  return (
    <XTable
      defaultOrder="desc"
      defaultOrderBy="cartPrice"
      defaultRowsPerPage={50}
      rows={orders}
      columns={columns}
      onClickRow={handleClickRow}
      getRowClassName={getRowClassName}
    />
  );
}

Orders.propTypes = {
  showMultipleStores: PropTypes.bool,
  showCancelled: PropTypes.bool,
  orders: PropTypes.array,
};

Orders.defaultProps = {
  showMultipleStores: false,
  showCancelled: false,
  orders: [],
};

export default memo(Orders);
