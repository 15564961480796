import React, { memo, useCallback } from "react";

import { t } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import BlockIcon from "@material-ui/icons/Block";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

import PropTypes from "prop-types";

const useStyles = makeStyles({
  icon: {
    marginRight: 10,
    verticalAlign: "middle",
  },
});

export function useGetStatusLabel() {
  return useCallback((status, cancelReason) => {
    switch (status) {
      case "pending-payment":
        return t`Pending Payment (ORDER NOT PAID)`;
      case "payment-failed":
        return t`Pending Failed (ORDER NOT PAID)`;
      case "preparing-order":
        return t`Preparing Order`;
      case "order-ready":
        return t`Order Ready For Pick-Up`;
      case "order-cancelled-by-customer":
        return t`Order Cancelled By Customer`;
      case "order-cancelled-by-store":
        switch (cancelReason) {
          case "no-show":
            return t`Order Cancelled No Show`;
          default:
            return t`Order Cancelled By Store`;
        }
      case "complete":
        return t`Order Picked-Up By Customer`;
      default:
        return status;
    }
  }, []);
}

function OrderStatus({ status, cancelReason, showIcon }) {
  const classes = useStyles();
  const statusLabel = useGetStatusLabel()(status, cancelReason);
  switch (status) {
    case "pending-payment":
      return (
        <>
          {!!showIcon && <BlockIcon className={classes.icon} />}
          {statusLabel}
        </>
      );
    case "payment-failed":
      return (
        <>
          {!!showIcon && <BlockIcon className={classes.icon} />}
          {statusLabel}
        </>
      );
    case "preparing-order":
      return (
        <>
          {!!showIcon && <AddShoppingCartIcon className={classes.icon} />}
          {statusLabel}
        </>
      );
    case "order-ready":
      return (
        <>
          {!!showIcon && <DriveEtaIcon className={classes.icon} />}
          {statusLabel}
        </>
      );
    case "order-cancelled-by-customer":
      return (
        <>
          {!!showIcon && <BlockIcon className={classes.icon} />}
          {statusLabel}
        </>
      );
    case "order-cancelled-by-store":
      return (
        <>
          {!!showIcon && <BlockIcon className={classes.icon} />}
          {statusLabel}
        </>
      );
    case "complete":
      return (
        <>
          {!!showIcon && <DoneAllIcon className={classes.icon} />}
          {statusLabel}
        </>
      );
    default:
      return status;
  }
}

OrderStatus.propTypes = {
  status: PropTypes.string,
  showIcon: PropTypes.bool,
  cancelReason: PropTypes.string,
};
OrderStatus.defaultProps = {
  status: "",
  showIcon: true,
  cancelReason: "",
};

export default memo(OrderStatus);
