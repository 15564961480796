import { useEffect } from "react";

function js(strings, ...args) {
  return strings.reduce((acc, str, i) => {
    const arg = args[i];
    if (arg) {
      if (typeof arg === "function") {
        return `${acc}${str}${arg()}`;
      }
      return `${acc}${str}${arg}`;
    }
    return `${acc}${str}`;
  }, "");
}

export const scripts = {
  js,
};

export function useScript({ src, javascript }) {
  useEffect(() => {
    const script = document.createElement("script");

    if (src) {
      script.src = src;
    } else if (javascript) {
      script.innerText = javascript;
    }

    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src, javascript]);
}
