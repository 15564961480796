function stripWhitespaces(text) {
  return (text || "").replace(/\s/g, "");
}

class PhoneValidator {
  format(phone) {}

  isValid(phone) {}
}

class PhoneValidatorFrance extends PhoneValidator {
  format(phone) {
    return phone.replace(/^0/, "33");
  }
  fix(phone) {
    return stripWhitespaces(phone).replace(/^\+?33/, "0");
  }
  isValid(phone) {
    return phone.replace(/ /g, "").match(/^[0-9]{10}$/);
  }
}

class PhoneValidatorBelgium extends PhoneValidator {
  format(phone) {
    return phone.replace(/^0/, "32");
  }
  fix(phone) {
    return stripWhitespaces(phone).replace(/^\+?32/, "0");
  }
  isValid(phone) {
    return phone.replace(/ /g, "").match(/^[0-9]{9,10}$/);
  }
}

class PhoneValidatorLuxembourg extends PhoneValidator {
  format(phone) {
    return phone.replace(/^0/, "352");
  }
  fix(phone) {
    return stripWhitespaces(phone).replace(/^\+?352/, "");
  }
  isValid(phone) {
    return phone.replace(/ /g, "").match(/^[0-9]{5,9}$/);
  }
}

class PhoneValidatorSwitzerland extends PhoneValidator {
  format(phone) {
    return phone.replace(/^0/, "41");
  }
  fix(phone) {
    return stripWhitespaces(phone).replace(/^\+?41/, "0");
  }
  isValid(phone) {
    return phone.replace(/ /g, "").match(/^[0-9]{9}$/);
  }
}

const validators = {
  FR: new PhoneValidatorFrance(),
  BE: new PhoneValidatorBelgium(),
  CH: new PhoneValidatorSwitzerland(),
  LU: new PhoneValidatorLuxembourg(),
};

export function formatPhoneNumber({ phone, country }) {
  return validators[country].format(phone);
}
